import * as React from "react"

const IconsIcon = (props: React.SVGProps<any>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <path d="M106.66 215.41a29.89 29.89 0 0 0 42.59 0l84.66-85.62a76.63 76.63 0 0 0-5.41-112.62C199.84-6.75 156.41-4.67 128 21.38 99.66-4.73 56.22-6.86 27.47 17.19A76.7 76.7 0 0 0 22 129.8zM48 41.73a41.14 41.14 0 0 1 26.53-9.39A47.2 47.2 0 0 1 108 46.55l20 20.17 20-20.19c16.69-16.86 43-18.94 60-4.81a44.62 44.62 0 0 1 3.19 65.56l-81.72 85.64-84.69-85.61a44.71 44.71 0 0 1-12.72-33.47A44.12 44.12 0 0 1 48 41.73zM260.57 288h-48l-7.08-14.21A27.4 27.4 0 0 0 179.83 256h-71.71a27.4 27.4 0 0 0-25.66 17.75l-7 14.21h-48A27.42 27.42 0 0 0 0 315.35v169.26A27.42 27.42 0 0 0 27.43 512h233.14A27.42 27.42 0 0 0 288 484.61V315.35A27.42 27.42 0 0 0 260.57 288zM256 480H32V320h63.3l8.81-17.81 7-14.15h65.73l7.09 14.21 8.84 17.75H256zM484.62 0a24.05 24.05 0 0 0-3.93.33L343.55 23.18C330 25.44 320 38.94 320 54.86V166.8a81.36 81.36 0 0 0-32-6.47c-35.88 0-64 21.08-64 48s28.12 48 64 48 64-21.08 64-48V54.86a4.45 4.45 0 0 1 0-.65l128-21.33V135a80 80 0 0 0-32-6.65c-35.35 0-64 21.49-64 48s28.65 48 64 48 64-21.49 64-48V32c0-17.91-12.51-32-27.38-32zM288 224.33c-19.25 0-32-9.63-32-16s12.75-16 32-16 32 9.62 32 16-12.75 16-32 16zm160-32c-19.85 0-32-10.36-32-16s12.15-16 32-16 32 10.36 32 16-12.15 16-32 16zM144 336a64 64 0 1 0 64 64 64.07 64.07 0 0 0-64-64zm0 96a32 32 0 1 1 32-32 32 32 0 0 1-32 32zm280.19-152.84a16 16 0 0 0-28.63-14.32L328 400h110.31l-52.2 88.06a16 16 0 0 0 6 21.83A15.8 15.8 0 0 0 400 512a16 16 0 0 0 13.91-8.06L493.44 368H379.75z" />
    </svg>
  )
}

export default IconsIcon
